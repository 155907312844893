//@flow
/**
 * @file Kod odpowiedzialny za eksport danych z aplikacji webowej
 * @author Ryszard Trojnacki
 */
import React, {useCallback, useState} from 'react';
import {Button} from "react-bootstrap";
import msgs from "./Language";
import type {Workbook, Worksheet, Fill, Font, Style } from "exceljs";
import {parseDate} from "./DateTime";

let ExcelModule=null;

/**
 * Pomocnicza funkcja, która w trybie leniwym załaduje moduł exceljs
 */
async function createWorkbook(): Workbook {
    if(ExcelModule===null) {
        ExcelModule=await import("exceljs");
    }
    return new ExcelModule.Workbook();
}

const fontName='Calibri';

/**
 * Konwersja na Base64
 */
const base64Encode = (bytes: Uint8Array): string => {
    const CHUNK_SIZE = 0x8000
    const arr = []
    for (let i = 0; i < bytes.length; i += CHUNK_SIZE) {
        arr.push(String.fromCharCode.apply(null, bytes.subarray(i, i + CHUNK_SIZE)))
    }
    return btoa(arr.join(''))
}

/**
 * Pomocnicza funkcja do tworzenia stylu Fill na podstawie kolorów.
 * @param color kolor tła w postaci HEX bez znaku `#`
 * @return {Fill}
 */
function fillColor(color: string): Fill {
    return {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: color }
    }
}


function headerFont(color: String): Font {
    return {
        name: fontName,
        size: 11,
        color: { argb: color },
        bold: true,
    }
}

const simpleBorder: Border = {
    color: { argb: '000000' },
    style: 'thin'
}

const cellLineBorder: Borders={
    bottom: simpleBorder,
    left: simpleBorder,
    right: simpleBorder,
    top: simpleBorder
}

const textCenter: Alignment = {
    horizontal: "center",
    vertical: "middle",
    wrapText: true,
}

/** Stała do konwersji z centymetrów do szerokości */
const cm2w=1/0.22;
/** Stała do konwersji z centymetrów do wysokości */
const cm2h = 1/0.0352;

export type ExportFormat='xlsx';

export type ExportOptions = $Shape<{
    /** Nazwa arkusza */
    sheetName: string;
}>;

export type ExportColumnInfo<Data> = {
    accessor: string|number|(row: Data) => any;

    /** Nagłówek kolumny */
    label: string;
    /** Szerokość kolumny w centymetrach */
    width?: number;
    /** Dodatkowy opis do nagłówka */
    hint?: string;
    /** Rodzaj wartości w kolumnie */
    type?: 'number'|'date'|'string'|'money';
    /** Waluta dla kolumny typu Money */
    currency?: string;
    /** Formatowanie liczby dla danych typu `number` */
    numberFormat?: string;
    /** Wyśrodkowanie tekstu */
    align?: 'center'|'left'|'right';
}

type RowFunction=(row: Row, data: Array<any>) => void;

/**
 * Funkcja do poprawiania daty w trybie UTC
 * @param date data do poprawienia
 * @return {Date} poprawiona data
 */
export function fixUtcDate(date: Date|null|string|number): Date|null {
    const v=parseDate(date);
    if(v==null) return v;
    return new Date(v.getTime()-v.getTimezoneOffset()*60000);
}

/**
 * Funkcja eksportująca dane do pliku XLSX
 * @param columns informacje o kolumnach/nagłówek
 * @param data dane do eksportu
 * @param [options] opcje eksportu
 */
async function exportXLSXData<T>(columns: Array<ExportColumnInfo>, data: Array<T>|Iterable<T>, options?: ExportOptions) {
    if(typeof options!=='object') options={};

    const workbook: Workbook = await createWorkbook();
    const sheet: Worksheet = workbook.addWorksheet(options.sheetName || 'Sheet1');
    const cells:Array<RowFunction>=[];

    const header=sheet.addRow(columns.map(c => c.label));
    header.font={ name: fontName, bold: true, size: 11 };

    const headerStyle: Style= {
        fill: fillColor('CCCCCC'),
        font: headerFont('000000'),
    }

    for(let i=0;i<columns.length;++i) {
        /** Informacje o danych */
        const c=columns[i];
        /** Ustawienia kolumny w wynikowym arkuszu */
        const column=sheet.getColumn(i+1);
        /** Ustawienia wyglądu kolumny nagłówka */
        const headerCell=header.getCell(i+1);
        headerCell.fill=headerStyle.fill;
        headerCell.font=headerStyle.font;

        let align, width;

        let accessor;
        if(typeof(c.accessor)==='number' || typeof(c.accessor)==='string') accessor=(row) => row[c.accessor];
        else accessor=c.accessor;

        switch (c.type || 'string') {
            case 'number':
                width=3;
                align='right';
                cells.push((row, data) => {
                    const cell=row.getCell(i+1);
                    cell.value=accessor(data);
                    if(c.numberFormat) cell.numFmt=c.numberFormat;
                });
                break;
            case 'date':
                cells.push((row, data) => {
                    const cell=row.getCell(i+1);
                    cell.value=fixUtcDate(accessor(data));
                    cell.numFmt='yyyy-mm-dd';
                    // cell.numFmt='dd.mm.yyyy';
                });
                break;
            case 'money':
                cells.push((row, data) => {
                    const cell=row.getCell(i+1);
                    cell.value=accessor(data);
                    cell.numFmt='0.00 '+c.currency;
                });
                break;
            case 'string':
                cells.push((row, data) => {
                    const cell=row.getCell(i+1);
                    const v=accessor(data);
                    cell.value=(v===null || typeof(v)==='undefined') ? null : String(v);
                });
                break;
            default:
                cells.push((row, data) => {
                    const cell=row.getCell(i+1);
                    cell.value=accessor(data);
                });
                break;
        }

        if(c.width) column.width=c.width*cm2w;
        column.header=c.label;
        if(c.hint) headerCell.note=c.hint;
    }

    // sheet.columns=columns.map((c, i) => ({
    //     width: c.width*cm2w,
    //     key: i,
    //     header: c.label,
    // }: Partial<Column>));

    for(const dataRow of data) {
        const sheetRow=sheet.addRow(dataRow);
        for(let c of cells) c(sheetRow, dataRow);
    }
    const buffer=await workbook.xlsx.writeBuffer();
    return base64Encode(buffer);
}

/**
 * Funkcja eksportująca dane
 * @param filename nazwa pliku bez rozszerzenia
 * @param format format danych; obecnie tylko `xlsx`
 * @param columns informacje o kolumnach
 * @param data dane do eksportu
 * @param options dodatkowe opcje eksportu
 * @return {Promise<void>}
 */
export async function exportFile<T>(filename: string, format: ExportFormat, columns: Array<ExportColumnInfo>, data: Array<T>|Iterable<T>, options?: ExportOptions) {
    const element=document.createElement("a");
    element.style.display='none';

    const href='data:application/vnd.openxmlformats;base64,'+await exportXLSXData(columns, data, options);
    const ext="xlsx";

    element.setAttribute('href', href);
    element.setAttribute("download", filename+'.'+ext);

    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}

/**
 * Komponent do eksportu danych.
 */
export const DataExportButton = ({ run }: {
    run: (format: ExportFormat) => Promise<void>;
}) => {
    const [ processing, setProcessing ] = useState(false);
    const handleExport=useCallback(async () => {
        setProcessing(true);
        try {
            await run('xlsx');
        } finally {
            setProcessing(false);
        }
    }, [ setProcessing, run ]);

    return <Button
        variant="link"
        onClick={handleExport}
        disabled={processing}
    ><span className="icon gradient-circle mr-2"><span className="icon-download2"/></span> {msgs.gui.labelExport}
    </Button>
}